import _ from 'lodash';
import { PATH_SEPARATOR } from '@/hybrid/assetGroupEditor/assetGroup.constants';
import {
  findChild,
  findExistingAsset,
  getColumnsHelper,
  getNameWithoutPath,
  getOneLevelUpPath,
  getParametersUsedByFormula,
  getPath,
} from '@/hybrid/assetGroupEditor/assetGroup.utilities';
import { PersistenceLevel, Store } from '@/core/flux.service';
import {
  AssetGroup,
  AssetGroupAsset,
  AssetGroupChild,
  AssetGroupInputParameter,
  AssetGroupStoreState,
} from '@/hybrid/assetGroupEditor/assetGroup.types';
import { sqAssetGroupStore, sqWorkbenchStore } from '@/core/core.stores';

export class AssetGroupStore extends Store {
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  static readonly storeName = 'sqAssetGroupStore';

  initialize() {
    this.state = this.immutable({
      id: '',
      assets: [] as AssetGroupAsset[],
      name: '',
      description: '',
      errors: [],
      isLoading: false,
      hasUnsavedChanges: false,
      nameError: false,
    });
  }

  get id(): string {
    return this.state.get('id');
  }

  get assets() {
    return this.state.get('assets');
  }

  get name(): string {
    return this.state.get('name');
  }

  get nameError(): boolean {
    return this.state.get('nameError');
  }

  get description(): string {
    return this.state.get('description');
  }

  get errors() {
    return this.state.get('errors');
  }

  get isLoading(): boolean {
    return this.state.get('isLoading');
  }

  get hasUnsavedChanges(): boolean {
    return this.state.get('hasUnsavedChanges');
  }

  private addCalculationChildToAsset(
    asset: AssetGroupAsset,
    { name, formula, parameters, manuallyAdded = false }: AssetGroupChild,
  ) {
    const actuallyUsedParameters = getParametersUsedByFormula(parameters, formula);
    // We have to re-read here or else changes in dependent columns aren't present for subsequent updates
    const idx = this.assets.findIndex((storeAsset: any) => storeAsset.name === asset.name);
    const cursor = this.state.select('assets', idx);
    if (cursor.exists()) {
      const children = _.cloneDeep(cursor.get('children')) as AssetGroupChild[];
      const existingChildIdx = _.findIndex(children, (child) => child?.name === name);
      // manually added attributes that get added for the first time should be treated like brand new children
      // for children that are being updated with a new signal we need to keep track so we can update the
      // underlying formula not the asset
      if (existingChildIdx > -1) {
        const existingChild = children[existingChildIdx];
        children.splice(existingChildIdx, 1);
        children.push({ ...existingChild, formula, parameters: actuallyUsedParameters });
      } else {
        children.push({
          type: '',
          columnType: 'Calculation',
          formula,
          parameters: actuallyUsedParameters,
          name,
          manuallyAdded,
        });
      }
      cursor.merge({
        ...asset,
        children,
      });
    }
  }

  // This DOES NOT add Calculations - this just adds "pass-thru" signals
  private addChildToAsset(asset: AssetGroupAsset, child: AssetGroupChild) {
    const idx = _.findIndex(this.state.get('assets'), (current: AssetGroupAsset) => {
      return getNameWithoutPath(current) === asset.name && current.assetPath === asset.assetPath;
    });

    const cursor = this.state.select('assets', idx);

    if (cursor.exists()) {
      const children = cursor.get('children');
      const existingChild = _.find(asset.children, (existingChild) => child.name === existingChild.name);
      // manually added attributes that get added for the first time should be treated like brand new children
      // for children that are being updated with a new signal we need to keep track so we can update the
      // underlying formula not the asset
      const updatedChild = { ...existingChild, ...child };
      // we only want to track the "original" mapping - the first ever assigned
      if (!existingChild) {
        updatedChild.originalMapping = _.get(child, 'parameters[0].item.id');
      }
      cursor.merge({
        ...asset,
        children: [..._.filter(children, (existingChild) => child.name !== existingChild.name), updatedChild],
      });
    }
  }

  private addChild(asset: AssetGroupAsset, child: AssetGroupChild) {
    if (child.columnType === 'Calculation') {
      this.addCalculationChildToAsset(asset, child);
    } else {
      this.addChildToAsset(asset, child);
    }
  }

  private updateAssetName({ original, newName }: { original: AssetGroupAsset; newName: string }) {
    const assets = _.map([...this.state.get('assets')], (asset) =>
      asset.name === original.name && getPath(asset) === getPath(original) ? { ...asset, name: newName } : asset,
    );

    this.state.set('assets', assets);
  }

  private addEmptyChildrenToAsset(asset: AssetGroupAsset) {
    const existingAsset: AssetGroupAsset | null = findExistingAsset(this.state.get('assets'), asset);
    if (!existingAsset) return;
    const emptyColumns = _.filter(
      getColumnsHelper(this.state.get('assets')),
      (child) => child.columnType !== 'Calculation' && _.isEmpty(child.parameters),
    );
    _.forEach(emptyColumns, (emptyColumn) => {
      this.addChildToAsset(asset, {
        name: emptyColumn.name,
        manuallyAdded: sqWorkbenchStore.currentUser.id,
        parameters: [],
        columnType: 'Item',
        formula: '',
      });
    });
  }

  private addAllCalculatedChildrenToAsset(asset: AssetGroupAsset) {
    const existingAsset: AssetGroupAsset | null = findExistingAsset(this.state.get('assets'), asset);
    if (!existingAsset) return;
    const calculationColumns = _.filter(getColumnsHelper(this.state.get('assets')), { columnType: 'Calculation' });
    _.forEach(calculationColumns, (calcColumn) => {
      // We need to modify the params so the formulas properly map to the correct signal - if it's a previously
      // saved assetGroupColumnBased column we don't want to use an existing id as that would be bad
      const parameters = _.map(calcColumn.parameters, (param) =>
        param.assetGroupColumnBased ? _.omit(param, 'item.id') : param,
      ) as AssetGroupInputParameter[];

      this.addCalculationChildToAsset(existingAsset, {
        ...calcColumn,
        id: '',
        parameters,
      });
    });
  }

  protected readonly handlers = {
    ASSET_GROUP_SET_ROOT: (assetGroup: AssetGroup) => {
      this.state.merge({ ...assetGroup });
    },
    ASSET_GROUP_ADD_EMPTY_ASSET: (asset: AssetGroupAsset) => {
      // adds a new asset from asset group toolbar button click
      this.state.set('assets', [...this.state.get('assets'), asset]);
      // add empty 'item' children placeholder
      this.addEmptyChildrenToAsset(asset);
      // also add calculated children
      this.addAllCalculatedChildrenToAsset(asset);
    },
    ASSET_GROUP_ADD_ASSET: (asset: AssetGroupAsset) => {
      const assetsInStoreBeforeAdd = this.state.get('assets');
      const existingAsset = findExistingAsset(assetsInStoreBeforeAdd, asset);
      if (existingAsset && !_.isEmpty(existingAsset)) {
        _.forEach(asset.children, (child) => {
          this.addChild(existingAsset, child);
        });
      } else {
        // adds a new asset entry. this will add all the "children" of the assets as well as calculation children
        this.state.set('assets', [...this.state.get('assets'), { ..._.omit(asset, 'children'), children: [] }]);
        const existingAsset = findExistingAsset(this.state.get('assets'), asset);
        if (existingAsset) {
          _.forEach(asset.children, (child) => {
            this.addChildToAsset(existingAsset, child);
          });
        }
        // we also want to add all the calculations to this newly added asset!
        this.addAllCalculatedChildrenToAsset(asset);
      }

      // to ensure unique names we prefix asset names with as much of their path as required to make them "unique"
      this.state.commit();

      const assetsInStore = this.state.get('assets');

      let assetsWithSameName = _.chain(assetsInStore)
        .filter((storeAsset) => getNameWithoutPath(storeAsset) === asset.name)
        .map((asset) => _.omit(asset, 'path'))
        .value();

      if (_.size(assetsWithSameName) > 1) {
        while (_.size(assetsWithSameName) > 0) {
          assetsWithSameName = getOneLevelUpPath(assetsWithSameName);
          const paths = _.map(assetsWithSameName, 'path');
          // now every asset has a "path" property - make sure all those paths are unique:
          assetsWithSameName = _.filter(assetsWithSameName, (current) => {
            const dupePaths = _.filter(paths, (p) => p === current.path);
            if (_.size(dupePaths) === 1) {
              const originalName = getNameWithoutPath(current);
              this.updateAssetName({
                original: current,
                newName: `${current.path}${PATH_SEPARATOR}${originalName}`,
              });
              return false;
            }
            return true;
          });
        }
      }
    },
    ASSET_GROUP_UPDATE_CHILD_PARAMETER: ({
      asset,
      child,
      parameterItemName,
    }: {
      asset: AssetGroupAsset;
      child: AssetGroupChild;
      parameterItemName: string;
    }) => {
      // We need to update parameters for calculations that are based on an asset group column so that the id no
      // longer points to the deleted item. If we do not update the id to an empty string it will look as if the
      // calculation "reverted" to the signal that the column was based on.
      const updatedChild = _.cloneDeep(child);
      const parameterIndex = _.findIndex(
        updatedChild.parameters,
        (param: AssetGroupInputParameter) => param.item.name === parameterItemName,
      );
      if (parameterIndex > -1 && updatedChild.parameters[parameterIndex].assetGroupColumnBased) {
        const tempParams = [...child.parameters];
        const paramToUpdate = _.cloneDeep(child.parameters[parameterIndex]);
        paramToUpdate.item.id = '';
        _.pullAt(tempParams, parameterIndex);
        const updatedParameters = [...tempParams, paramToUpdate];
        this.addCalculationChildToAsset(asset, { ...child, parameters: updatedParameters });
      }
    },
    ASSET_GROUP_RESTORE_CHILD: ({
      asset,
      name,
      newItemId,
    }: {
      asset: AssetGroupAsset;
      name: string;
      newItemId: string;
    }) => {
      const child = findChild(asset, name);
      if (child) {
        const updatedChild = _.cloneDeep(child);
        if (!_.isEmpty(updatedChild.parameters) && updatedChild.parameters[0].item) {
          updatedChild.parameters[0].item.id = newItemId;
        }
        this.addChildToAsset(asset, updatedChild);
      }
    },
    ASSET_GROUP_ADD_CHILD_TO_ASSET: ({ asset, child }: { asset: AssetGroupAsset; child: AssetGroupChild }) => {
      this.addChildToAsset(asset, child);
    },
    ASSET_GROUP_ADD_CALCULATION_CHILD_TO_ASSET: ({
      asset,
      child,
    }: {
      asset: AssetGroupAsset;
      child: AssetGroupChild;
    }) => {
      this.addCalculationChildToAsset(asset, child);
    },
    ASSET_GROUP_SET_NAME: ({ name }: { name: string }) => {
      this.state.set('name', name);
    },
    ASSET_GROUP_SET_NAME_ERROR: ({ error }: { error: string }) => {
      this.state.set('nameError', error);
    },
    ASSET_GROUP_SET_DESCRIPTION: ({ description }: { description: string }) => {
      this.state.set('description', description);
    },
    ASSET_GROUP_ADD_ATTRIBUTE: (newChild: AssetGroupChild) => {
      const { formula, parameters }: { formula: string; parameters: AssetGroupInputParameter[] } = newChild;
      if (formula) {
        newChild.parameters = getParametersUsedByFormula(parameters, formula);
      }
      this.state.set(
        'assets',
        _.map([...this.state.get('assets')], (asset) => ({
          ...asset,
          children: [...asset.children, newChild],
        })),
      );
    },
    ASSET_GROUP_UPDATE_COLUMN_NAME: ({ originalName, newName }: { originalName: string; newName: string }) => {
      // be very careful changing the order of things here! we need to set the assets in the store BEFORE we add
      // update the calculation dependencies or we overwrite the required calculation updates!
      const assets = _.map([...this.state.get('assets')], (asset) => ({
        ...asset,
        children: _.map(asset.children, (child) => (child.name === originalName ? { ...child, name: newName } : child)),
      }));
      this.state.set('assets', assets);
      // DO NOT change the line below to use the assets const! we have to use the most current assets or the
      // addCalculationChildToAsset code will not work as expected.
      _.forEach(this.state.get('assets'), (asset) => {
        _.forEach(asset.children, (child) => {
          if (child.columnType === 'Calculation') {
            const parameterIndex = _.findIndex(
              child.parameters,
              (param: any) => param.item.name === originalName && param.assetGroupColumnBased,
            );
            if (parameterIndex > -1) {
              const tempParams = [...child.parameters];
              const paramToUpdate = _.cloneDeep(child.parameters[parameterIndex]);
              paramToUpdate.item.name = newName;
              _.pullAt(tempParams, parameterIndex);
              const updatedParameters = [...tempParams, paramToUpdate];
              this.addCalculationChildToAsset(asset, { ...child, parameters: updatedParameters });
            }
          }
        });
      });
    },
    ASSET_GROUP_UPDATE_ASSET_NAME: this.updateAssetName,
    ASSET_GROUP_RESET_STORE: () => {
      this.state.set({
        assets: [],
        name: '',
        description: '',
        hasUnsavedChanges: false,
      });
    },
    ASSET_GROUP_REMOVE_CHILD_FROM_ASSET: ({ asset, child }: { asset: AssetGroupAsset; child: AssetGroupChild }) => {
      const existingAsset = findExistingAsset(this.state.get('assets'), asset);
      if (existingAsset) {
        const idx = _.findIndex(this.state.get('assets'), existingAsset);
        const cursor = this.state.select('assets', idx);
        if (cursor.exists()) {
          const newChildren = [..._.filter(existingAsset.children, (childEntry) => childEntry.name !== child.name)];
          cursor.merge({
            ...existingAsset,
            children: newChildren,
          });
        }
      }
    },
    ASSET_GROUP_REMOVE_ASSET: ({ name }: { name: string }) => {
      const assets = [...this.state.get('assets')];
      _.remove(assets, ['name', name]);
      this.state.set('assets', assets);
    },
    ASSET_GROUP_REMOVE_ATTRIBUTE: ({ name }: { name: string }) => {
      const assets = _.map([...this.state.get('assets')], (asset) => ({
        ...asset,
        children: _.chain(asset.children)
          .map((child) => (child.name !== name ? child : null))
          .compact()
          .value(),
      }));

      this.state.set('assets', assets);
    },
    ASSET_GROUP_SET_IS_LOADING: ({ isLoading }: { isLoading: boolean }) => {
      this.state.set('isLoading', isLoading);
    },
    ASSET_GROUP_SET_HAS_UNSAVED_CHANGES: ({ hasUnsavedChanges }: { hasUnsavedChanges: boolean }) => {
      this.state.set('hasUnsavedChanges', hasUnsavedChanges);
    },
  };

  dehydrate() {
    return _.omit(this.state.serialize(), 'nameError');
  }

  rehydrate(dehydratedState: AssetGroupStoreState) {
    this.state.merge(dehydratedState);
  }
}
